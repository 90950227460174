.App {
  text-align: center;
  font-family: "Lucida Console", "Courier New", monospace;
}

.App-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.Welcome-text {
  font-size: calc(12px + 2vmin);
  color: greenyellow;
  width: 69%;
}

.App-link {
  font-size: calc(5px + 2vmin);
  text-decoration: none;
  color: white;
}

.App-link:hover {
  text-decoration: underline;
}

.App-link:active {
  text-decoration: underline;
}

.footer {
  font-size: calc(2vmin);
  text-decoration: none;
  color: white;
}
